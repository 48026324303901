<template>
  <div class="cardAccess">
    <div class="topImg"><img alt="" src="../../assets/Rectangle 50.png" /></div>
    <div class="leftImg"><img alt="" src="../../assets/Ellipse 18.png" /></div>
    <div class="title">Our Products</div>
    <div class="title-text">A variety of card schemes</div>
    <div style="text-align: center">
      <div class="text">
        We assist with the card issuing process and enable instant fund transfer
        in any way you choose to spend them, anywhere.
      </div>
    </div>
    <!-- 第一个卡片 -->
    <div class="card">
      <div class="catd-left">
        <img src="../../assets/card/card.png" alt="" />
      </div>
      <div class="card-right">
        <div class="card-right-title">Generic Card</div>
        <div class="card-right-text">
          We offer a solution which is able to facilitate off-the-shelf generic
          card schemes which allow you to immediately launch a card program to
          facilitate disbursements for your customers or members.
        </div>
        <div class="card-right-center">
          <img src="../../assets/card/card-icon.png" alt="" />
          <img src="../../assets/card/card-icon1.png" alt="" />
        </div>
        <div class="card-right-bottom">
          <div v-for="(item, index) in list" :key="index">
            <img src="../../assets/card/card-icon3.png" alt="" />
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 第二个卡片 -->
    <div class="card">
      <div v-show="width == 2" class="catd-left">
        <img class="width-h5" src="../../assets/card/card1.png" alt="" />
      </div>
      <div class="card-right">
        <div class="card-right-title">Full Brand Card</div>
        <div class="card-right-text">
          Your cards display your logo prominently and you decide nearly every
          design element.
        </div>
        <div class="card-right-center">
          <img src="../../assets/card/card-icon.png" alt="" />
          <img src="../../assets/card/card-icon1.png" alt="" />
        </div>
        <div class="card-right-bottom">
          <div v-for="(item, index) in list1" :key="index">
            <img src="../../assets/card/card-icon3.png" alt="" />
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
      <div v-show="width == 1" class="catd-left">
        <img src="../../assets/card/card1.png" alt="" />
      </div>
    </div>
    <!-- 第三个卡片 -->
    <div class="card">
      <div class="catd-left">
        <img src="../../assets/card/card2.png" alt="" />
      </div>
      <div class="card-right">
        <div class="card-right-title">Virtual Card</div>
        <div class="card-right-text">
          Cardholders can load virtual cards into their digital wallets to
          enable instant and secure access to pay—online, in-app, or in-store.
        </div>
        <div class="card-right-center">
          <img src="../../assets/card/card-icon.png" alt="" />
          <img src="../../assets/card/card-icon1.png" alt="" />
        </div>
        <div class="card-right-bottom">
          <div v-for="(item, index) in list2" :key="index">
            <img src="../../assets/card/card-icon3.png" alt="" />
            <span>{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center">
      <a class="bottom" href="#" @click="$router.push('/cardAccessDetails')">
        Learn more
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "cardAccess",
  data() {
    return {
      width: 1, // 1 web 2 H5
      list: [
        "Set up in a short time",
        "Medium transaction and set up fee",
        "Physical card with metal card design option",
        "Access to a portal for card management and loading",
        "Bookkeeping and expense management",
      ],
      list1: [
        "Set up within 10 weeks",
        "High set up fee",
        "Physical card and virtual card, with metal card design support",
        "API support",
        "Multi-currency support",
        "Access to a portal for card management and loading",
        "Bookkeeping and expense management",
      ],
      list2: [
        "Set up rapidly",
        "Lower transaction fee and set up fee",
        "API support",
        "Real time spend tracking",
        "Access to a portal for card management and loading",
        "Bookkeeping and expense management",
      ],
    };
  },
  created() { },
  mounted() {
    //获取页面宽度
    this.checkMediaQuery();
    window.addEventListener("resize", this.checkMediaQuery);
  },
  methods: {
    //监听页面宽度变化
    checkMediaQuery() {
      const mediaQueryList = window.matchMedia("(max-width: 768px)");
      if (mediaQueryList.matches) {
        if (this.width == 1) this.width = 2;
      } else {
        if (this.width == 2) this.width = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../css/public.scss";

.cardAccess {
  margin-bottom: 260px;
}

.leftImg {
  width: 400px;
  height: 410px;
  position: absolute;
  top: 0px;
}

.topImg {
  width: 100%;
  height: 720px;
  position: absolute;
  top: 0;
  left: 0;
}

.title {
  text-align: center;
  font-size: 56px;
  color: #000;
  padding-top: 186px;
}

.title-text {
  text-align: center;
  color: rgba(30, 37, 58, 0.6);
  font-size: 24px;
  padding-top: 20px;
  font-weight: 400;
}

.text {
  margin: 60px auto 0;
  padding: 20px 64px;
  display: inline-block;
  line-height: 24px;
  font-size: 14px;
  background: rgba(64, 71, 214, 0.1);
  color: #4047d6;
}

.card {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 100px;

  .catd-left {
    img {
      width: 100%;
      max-width: 540px;
    }
  }

  .card-right {
    text-align: left;

    .card-right-title {
      font-size: 22px;
    }

    .card-right-text {
      max-width: 650px;
      margin-top: 30px;
      margin-bottom: 60px;
      font-size: 16px;
      line-height: 28px;
      color: rgba(30, 37, 58, 0.6);
    }
  }

  .card-right-center {
    .card-right-center-img {
      opacity: 0.1;
    }

    :nth-child(1) {
      margin-left: 0 !important;
    }

    :nth-child(2) {
      width: 49px !important;
    }

    img {
      display: inline-block;
      margin-left: 32px;
      height: 20px;
      width: 33px;
    }
  }

  .card-right-bottom {
    div {
      display: flex;
      align-items: center;
      margin-top: 10px;

      img {
        height: 12px;
        width: 16px;
      }

      span {
        display: inline-block;
        margin-left: 20px;
        font-size: 14px;
        color: rgba(30, 37, 58, 0.6);
      }
    }
  }
}

.bottom {
  margin-top: 120px;
  display: inline-block;
  background: #4047d6;
  border-radius: 30px;
  line-height: 60px;
  padding: 0 70px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  opacity: 0.8;
  text-decoration: none;
  outline: none;
}

@media screen and (max-device-width: 768px) {
  .title {
    padding-top: 360px;
    font-size: 102px;
  }

  .title-text {
    font-size: 48px;
  }

  .text {
    margin: 120px auto 0;
    padding: 40px 128px;
    display: inline-block;
    line-height: 100px;
    font-size: 40px;
    background: rgba(64, 71, 214, 0.1);
    color: #4047d6;
  }

  .card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 0 100px;
    margin-top: 300px;

    .catd-left {
      img {
        width: 100%;
        max-width: 100%;
      }

      .width-h5 {
        width: 80%;
        max-width: 80%;
        margin: auto;
      }
    }

    .card-right {
      text-align: left;
      width: 100%;
      margin-top: 100px;

      .card-right-title {
        font-size: 70px;
      }

      .card-right-text {
        max-width: 1300px;
        margin-top: 80px;
        margin-bottom: 80px;
        font-size: 50px;
        line-height: 100px;
        color: rgba(30, 37, 58, 0.6);
      }
    }

    .card-right-center {
      .card-right-center-img {
        opacity: 0.1;
      }

      :nth-child(1) {
        margin-left: 0 !important;
      }

      :nth-child(2) {
        width: 100px !important;
      }

      img {
        display: inline-block;
        margin-left: 32px;
        height: 55px;
        width: 90px;
      }
    }

    .card-right-bottom {
      div {
        display: flex;
        align-items: center;
        margin-top: 30px;

        img {
          height: 40px;
          width: 50px;
        }

        span {
          display: inline-block;
          margin-left: 20px;
          font-size: 50px;
          color: rgba(30, 37, 58, 0.6);
        }
      }
    }

  }

  .bottom {
    margin-top: 260px;
    display: inline-block;
    background: #4047d6;
    border-radius: 60px;
    line-height: 140px;
    padding: 0 160px;
    color: #fff;
    font-size: 60px;
    cursor: pointer;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    opacity: 0.8;
    text-decoration: none;
    outline: none;
  }
}
</style>